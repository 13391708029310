import { getFQCN } from "@/utils/functions/fqcn";
import {
  LuCrown,
  LuDatabase,
  LuLayoutDashboard,
  LuLink,
  LuSettings,
  LuUsers2,
} from "react-icons/lu";

const fqcn_bui = {
  Bundle: "sidebarMenuBundle",
  Unit: "menu",
  Interface: "Listing",
};

export const SidebarMenus = [
  {
    title: "Dashboard",
    icon: (
      <LuLayoutDashboard
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "dashboard-icon")}
      />
    ),
    link: "/dashboard",
  },
  {
    title: "Tenants",
    icon: (
      <LuDatabase
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "tenants-icon")}
      />
    ),
    link: "/tenants",
  },
  {
    title: "Hostnames",
    icon: (
      <LuLink
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "hostnames-icon")}
      />
    ),
    link: "/hostnames",
  },
  {
    title: "Parameters",
    icon: (
      <LuSettings
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "parameters-icon")}
      />
    ),
    link: "/parameters",
  },
  {
    title: "Users",
    icon: (
      <LuUsers2
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "users-icon")}
      />
    ),
    link: "/users",
  },
  {
    title: "Ownerships",
    icon: (
      <LuCrown
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "ownerships-icon")}
      />
    ),
    link: "/ownerships",
  },
];
