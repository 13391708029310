"use client";

// React
import React, { useState, FC, useEffect } from "react";

// Next
import { usePathname, useRouter } from "next/navigation";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";

// React Icons
import { HiLogin, HiLogout } from "react-icons/hi";
import { IoMenu } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa6";

// JS Cookie
import cookie from "js-cookie";

// Components
import { Button } from "@/components/Button";
import ModeToggle from "@/components/ModeToggle";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/Tooltip";
import Logo from "@/components/Logo";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover";
import { Skeleton } from "../Skeleton";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "../Breadcrumb";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";
import { CiLogout } from "react-icons/ci";

type Props = {
  fqcn_bui: IFQCN_BUI;
  sidebarMenu?: boolean;
  setSidebarOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navbar: FC<Props> = ({
  fqcn_bui,
  sidebarMenu = false,
  setSidebarOpen,
}) => {
  const [tokenLoading, setTokenLoading] = useState(true);
  const [username, setUsername] = useState("");

  const t = useTranslations("Navbar");
  const { push } = useRouter();
  const locale = useLocale();

  const handleLogout = () => {
    cookie.remove("currentUser");
    cookie.remove("roles");
    localStorage.removeItem("username");
    localStorage.removeItem("roles");
    sessionStorage.clear();
    window.location.reload();
  };

  const [breadcrumb, setBreadcrumb] = useState<string[]>([]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.location.pathname?.split("/").map((item) => {
        if (item !== "" && item !== "en" && item !== "fr") {
          setBreadcrumb((prev) => [...prev, item]);
        }
      });
    }

    return () => {
      setBreadcrumb([]);
    };
  }, []);

  const token = cookie.get("currentUser");
  useEffect(() => {
    const fetchToken = () => {
      if (token) {
        setTokenLoading(true);
      }
      setTokenLoading(false);
    };

    if (typeof window !== "undefined") {
      const username = localStorage.getItem("username");
      setUsername(username || "");
    }

    fetchToken();
  }, []);

  return (
    <article className="w-full p-3 flex justify-between items-center gap-2 md:gap-0 print:hidden bg-gray-300 dark:bg-slate-700 h-[94px]">
      {sidebarMenu ? (
        <div className="w-full flex items-center gap-1">
          <Button
            id={getFQCN(fqcn_bui, "menu", "collapsible-button")}
            className="w-7 h-7 p-0 bg-transparent hover:bg-transparent relative md:hidden"
            onClick={() => setSidebarOpen && setSidebarOpen((prev) => !prev)}
          >
            <IoMenu
              id={getFQCN(fqcn_bui, "menu", "collapsible-icon")}
              className="w-7 h-7 text-white"
            />
          </Button>

          <Breadcrumb
            id={getFQCN(fqcn_bui, "breadcrumb")}
            className="md:w-full md:ml-5 print:hidden"
          >
            <BreadcrumbList id={getFQCN(fqcn_bui, "list-breadcrumb")}>
              <BreadcrumbItem id={getFQCN(fqcn_bui, "item-breadcrumb")}>
                <Link
                  id={getFQCN(fqcn_bui, "link-breadcrumb")}
                  href={locale === "fr" ? `/fr` : `/en`}
                  prefetch={false}
                >
                  {t("Home")}
                </Link>
              </BreadcrumbItem>
              {breadcrumb?.map((item, _id) => (
                <React.Fragment key={_id}>
                  <BreadcrumbSeparator
                    id={getFQCN(fqcn_bui, "separator-breadcrumb")}
                  >
                    /
                  </BreadcrumbSeparator>
                  <BreadcrumbItem>
                    {breadcrumb?.length - 1 === _id ? (
                      <BreadcrumbPage
                        id={getFQCN(fqcn_bui, "page-breadcrumb")}
                        className="capitalize"
                      >
                        {isNaN(Number(item))
                          ? t(
                              (
                                item?.charAt(0).toUpperCase() + item?.slice(1)
                              )?.replace("-", " ")
                            )
                          : item}
                      </BreadcrumbPage>
                    ) : (
                      <Link
                        id={getFQCN(fqcn_bui, "link-breadcrumb")}
                        prefetch={false}
                        href={
                          !isNaN(parseInt(item))
                            ? `/${locale}/${breadcrumb[_id - 1]}/${item}`
                            : `/${locale}/${item}`
                        }
                      >
                        {isNaN(Number(item))
                          ? t(
                              (
                                item?.charAt(0).toUpperCase() + item?.slice(1)
                              )?.replace("-", " ")
                            )
                          : item}
                      </Link>
                    )}
                  </BreadcrumbItem>
                </React.Fragment>
              ))}
            </BreadcrumbList>
          </Breadcrumb>
        </div>
      ) : (
        <Logo
          fqcn_bui={{
            Bundle: "homeBundle",
            Unit: "logo",
            Interface: "landing",
          }}
        />
      )}

      <div className="flex gap-4 w-fit md:w-full justify-end items-center">
        {username && token && (
          <h4 className="hidden md:block">{t("Welcome") + " " + username}</h4>
        )}

        {tokenLoading ? (
          <Skeleton className="h-10 w-full  bg-gray-50 max-w-32" />
        ) : (
          token && (
            <div>
              <div className="hidden md:block">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        id={getFQCN(fqcn_bui, "logout-button")}
                        name="logout-button"
                        className="bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue"
                        onClick={handleLogout}
                      >
                        <HiLogin className="w-5 h-5 text-white print:text-black" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("Logout")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>

              <div className="block md:hidden">
                <Popover>
                  <PopoverTrigger asChild>
                    <Button
                      variant="outline"
                      className="border-gray-300"
                      size="icon"
                      id={getFQCN(fqcn_bui, "mode-toggle")}
                    >
                      <FaRegUser className="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all" />
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent
                    align="end"
                    className="flex flex-col gap-3 w-fit"
                  >
                    <h4 className="text-center">
                      {t("Welcome") + " " + username}
                    </h4>
                    <Button
                      id={getFQCN(fqcn_bui, "logout-button")}
                      name="logout-button"
                      className="h-8 bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue w-full text-white print:text-black"
                      onClick={handleLogout}
                    >
                      <HiLogout className="w-5 h-5" />
                      <p>{t("Logout")}</p>
                    </Button>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
          )
        )}

        <ModeToggle
          fqcn_bui={{
            Bundle: "navbarBundle",
            Unit: "navBar",
            Interface: "modeToggle",
          }}
        />
      </div>
    </article>
  );
};

export default Navbar;
